<template>
  <div class="header">
    <div class="logo">
      <!-- 血透系统 -->
      <img class="logoImg" src="@/assets/img/logo.png" />
    </div>
    <!-- 折叠按钮 -->
    <div class="collapse-btn">
      <i v-if="!collapse" @click="collapseChage" class="fa fa-align-right"></i>
      <i v-else class="fa fa-align-left" @click="collapseChage"></i>
      <div class="breadBox">
        <!--        <bread-crumb></bread-crumb>-->
      </div>
    </div>
    <div class="tagsView">
      <v-tags></v-tags>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <!-- <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="fa fa-arrows"></i>
          </el-tooltip>
        </div> -->
        <!-- 退出登录 -->
        <div class="user">
          <img class="img" src="@/assets/img/ions/userImg.png" />
          <span class="name">{{ username }}</span>
        </div>
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
          <el-tooltip
            effect="dark"
            :content="message ? `有${message}条未读消息` : `消息中心`"
            placement="bottom"
          >
            <router-link class="btn-tab" to="/tabs">
              <img class="img" src="@/assets/img/ions/bell.png" />
              <span class="name">消息通知</span>
            </router-link>
          </el-tooltip>
          <span class="btn-bell-badge" v-if="message"></span>
        </div> -->
        <div class="loginoutBox" @click="loginOuts">
          <i class="fa fa-sign-out"></i>
          <div class="but">退出</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { loginout } from '@/utils/tool'
// import breadCrumb from '@/components/Breadcrumb.vue'
import vTags from '../components/TagsView/index.vue'
export default {
  components: {
    // breadCrumb,
    vTags,
  },
  setup() {
    const username = localStorage.getItem('ms_username')
    const message = 2

    const fullscreen = ref(false)
    const handleFullScreen = () => {
      let element = document.documentElement
      if (fullscreen.value) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      fullscreen.value = !fullscreen.value
    }

    const store = useStore()
    const collapse = computed(() => store.state.collapse)
    // 侧边栏折叠
    const collapseChage = () => {
      store.commit('handleCollapse', !collapse.value)
    }

    onMounted(() => {
      // if (document.body.clientWidth < 1500) {
      //   collapseChage()
      // }
    })

    const loginOuts = () => {
      ElMessageBox.confirm('您确认要退出登录吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          loginout()
        })
        .catch(() => {})
    }

    return {
      fullscreen,
      handleFullScreen,
      username,
      message,
      collapse,
      collapseChage,
      loginOuts,
    }
  },
}
</script>
<style scoped lang="scss">
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  font-size: 22px;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.12);
  color: #000;
  display: flex;
}
.collapse-btn {
  float: left;
  // padding: 0 21px;
  // cursor: pointer;
  // line-height: 72px;
  .fa {
    float: left;
    margin-top: 14px;
    cursor: pointer;
  }
}
.header .logo {
  float: left;
  width: 200px;
  line-height: 48px;
  text-align: center;
  .logoImg {
    height: 42px;
    margin-top: 2px;
  }
}
.header-right {
  float: right;
  margin-right: 24px;
}
.header-user-con {
  display: flex;
  height: 48px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
  .btn-tab {
    display: flex;
    align-items: center;
    color: #444444;
  }
  .img {
    margin-right: 8px;
  }
}
.btn-bell-badge {
  position: absolute;
  left: 16px;
  top: 1px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user {
  display: flex;
  align-items: center;
  margin-right: 20px;
  .img {
    width: 24px;
    height: 24px;
    border-right: 50%;
    margin-right: 8px;
  }
  .name {
    font-size: 14px;
    color: #444444;
  }
}
.loginoutBox {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Source Han Sans CN-Regular';
  color: #333333;
  cursor: pointer;
  .but {
    margin-left: 8px;
  }
}
.tagsView {
  width: calc(100vw - 378px);
}
</style>
