<template>
  <el-scrollbar ref="scrollContainer" class="scroll-container">
    <slot />
  </el-scrollbar>
</template>

<script>
import { ref } from 'vue'
export default {
  setup() {
    const scrollContainer = ref(null)
    return {
      scrollContainer,
    }
  },
}
</script>
