const tagView = {
  namespaced: true,
  state: {
    visitedViews: [],
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (view.meta.hiddleTag) return
      if (state.visitedViews.some((v) => v.path === view.path)) return
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name',
        })
      )
    },
    DEL_VISITED_VIEW: (state, view) => {
      const v = state.visitedViews
      for (let i = 0; i < v.length; i++) {
        if (v[i].path === view.path) {
          state.visitedViews.splice(i, 1)
          break
        }
      }
    },
  },
  actions: {
    addView({ dispatch }, view) {
      dispatch('addVisitedView', view)
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },
    delView({ dispatch, state }, view) {
      return new Promise((resolve) => {
        dispatch('delVisitedView', view)
        // dispatch('delCachedView', view)
        resolve({
          visitedViews: [...state.visitedViews],
        })
      })
    },
    delVisitedView({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_VISITED_VIEW', view)
        resolve([...state.visitedViews])
      })
    },
    delOthersViews({ dispatch, state }, view) {
      return new Promise((resolve) => {
        dispatch('delOthersVisitedViews', view)
        // dispatch('delOthersCachedViews', view)
        resolve({
          visitedViews: [...state.visitedViews],
          // cachedViews: [...state.cachedViews]
        })
      })
    },
    delOthersVisitedViews({ commit, state }, view) {
      return new Promise((resolve) => {
        commit('DEL_OTHERS_VISITED_VIEWS', view)
        resolve([...state.visitedViews])
      })
    },
  },
}

export default tagView
