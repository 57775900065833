import router, { routes } from '@/router'
import Home from '@/views/Home.vue'
import service from '@/utils/request'
const state = {
  routes: routes,
  addRoutes: [],
  HZheName:''
}

const mutations = {
  SET_ROUTES: (state, route) => {
    state.addRoutes = route
    // state.routes = routes
    state.routes = route.concat(routes)
  },
  HzheFunctionName:(state,pathName) => {
    state.HZheName = pathName
  }
}
const actions = {
  async generateRoutes({ commit },name) {
    let view = []
    let role_id = JSON.parse(localStorage.getItem('ms_userData')).role_id
    console.log(role_id)
    let res = await service.post('/api/RolePermission/getRoleMenu', {
      role_id: role_id,
    })
    if (res.code === 0) {
      view = res.data
      console.log(view[0])
    }
    return new Promise((resolve) => {
      let accessedRoutes
      const rolRout = []
      const hasHome = view.some((role) => {
        return role.component == 'dashboard'
      })
      let parHome = ''
      if (hasHome) {
        parHome = {
          path: '/',
          redirect: '/dashboard',
          meta: { hidden: true, hiddleTag: true },
        }
      } else {
        parHome = {
          path: '/',
          redirect: view[0].key,
          meta: { hidden: true, hiddleTag: true },
        }
      }
      rolRout.push(parHome)
      router.addRoute(parHome)
      view.map((item) => {
        console.log(item.is_use)
        let hidden = item.is_use == 0 ? true : false
        let data = {
          path: '/' + item.component,
          name: item.component,
          redirect: item.key,
          component: Home,
          meta: { title: item.name, icon: item.icon, hidden: hidden },
          children: [],
        }
        if (item.children && item.children.length > 0) {
          item.children.map((obj) => {
            let hidden2 = obj.is_use == 0 ? true : false
            const data2 = {
              path: obj.component,
              name: obj.component,
              menuPermission: obj.menuPermission,
              meta: {
                title: obj.name,
                hidden: hidden2,
                menuPermission: obj.menuPermission,
              },
              component: () => import(`@/views/${obj.key}`),
            }
            data.children.push(data2)
          })
        }
        if (item.component == 'dashboard') {
          data = {
            path: '/',
            name: 'Home',
            component: Home,
            children: [
              {
                path: '/dashboard',
                name: 'dashboard',
                meta: {
                  title: item.name,
                  icon: item.icon,
                  affix: true,
                  hidden: hidden,
                  menuPermission: item.menuPermission,
                },
                component: () => import('@/views/Dashboard.vue'),
              },
            ],
          }
        }
        if (item.component == 'patients') {
          data.children.push({
            path: 'patientDetails/:id?',
            name: 'patientDetails',
            meta: { title: `病历详情`, hidden: true },
            component: () => import('@/views/patient/Details/index.vue'),
          })
        }
        rolRout.push(data)
        router.addRoute(data)
      })
      accessedRoutes = rolRout || []
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
