import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import 'lib-flexible'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import print from 'vue3-print-nb'
import 'font-awesome/css/font-awesome.css'
import '@/assets/font/font.css'
import '@/assets/css/main.scss'

import '@/permission'
import permission from '@/directive/permission.js'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .directive('permissions', permission)
  .use(print)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .mount('#app')
