<template>
  <div class="side-bar">
    <el-menu
      class="elmenuBase default-skin"
      :default-active="activeRoutePath"
      text-color="#fff"
      active-text-color="#ffffff"
      :collapse="collapse"
    >
      <sidebar-item
        v-for="route in menus"
        :key="route.path"
        :item="route"
        :base-path="route.path"
        :changeSkin="changeSkin"
      ></sidebar-item>
    </el-menu>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import SidebarItem from './SideBarItem.vue'

export default {
  name: 'SideBar',
  components: {
    SidebarItem,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const userName = localStorage.getItem('ms_username')
    console.log(store.state.collapse)
    return {
      userPermissions: computed(() => store.state.user.permissions),
      collapse: computed(() => store.state.collapse),
      changeSkin: '7243ce',
      menus: computed(() => store.state.permission.routes),
      activeRoutePath: computed(() => route.path),
      userName,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.side-bar {
  width: 200px;
  height: 100%;
}
.elmenuBase {
  height: 100%;
  /* padding: 0 10px; */
  overflow-y: auto;
  &:deep(.el-sub-menu) {
    .el-menu-item,
    .el-sub-menu__title {
      font-size: 14px;
      color: #3166ae !important;
    }
    .el-menu-item {
      min-width: auto;
      line-height: 38px;
    }
  }
  &.el-menu {
    &:deep(.el-menu-item) {
      transition: initial;
      /* height: 38px;
      border-radius: 4px; */
      &.is-active {
        background-color: #3167a8;
        color: #ffffff !important;
        i {
          color: #ffffff !important;
        }
        &:focus,
        &:hover {
          background-color: #3167a8;
        }
      }
      &:focus,
      &:hover {
        background: rgba(49, 102, 174, 0.1);
      }
    }
  }

  // &:deep(.router-link-active) {
  //   background-color: #3167a8;
  //   display: block;
  //   .el-menu-item.is-active,
  //   .el-menu-item.is-active i {
  //     color: #ffffff !important;
  //   }
  // }
  &:deep(.nest-menu) {
    // .el-sub-menu__title:hover {
    //   background-color: #3167a8 !important;
    // }
    .el-menu-item,
    .el-sub-menu__title {
      line-height: 48px;
      font-size: 14px;
      color: #3166ae !important;
    }
    .el-menu-item * {
      vertical-align: middle;
    }
    .el-sub-menu .el-sub-menu__icon-arrow {
      font-size: 14px;
    }
  }
  &.el-menu--collapse {
    padding: 0;
    &:deep(.el-sub-menu > .el-sub-menu__title span) {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
      display: inline-block;
    }
    &:deep(.el-sub-menu > .el-sub-menu__title .el-sub-menu__icon-arrow) {
      display: none;
    }
  }
}
</style>
