import axios from 'axios'
import { ElMessage } from 'element-plus'
import { loginout } from '@/utils/tool'
import comm from '@/utils/comm'

let port = location.protocol
let ip = comm.cesuUrl
// let requestUrl = `${port}//${ip}` 
let requestUrl = `https://${ip}`

let isMagg = true

const service = axios.create({
  baseURL: requestUrl,
  // baseURL: '/api',
  timeout: 30000,
})

service.interceptors.request.use(
  (config) => {
    const role = localStorage.getItem('ms_token')
    if (role) {
      config.headers['token'] = role
    }
    return config
  },
  (error) => {
    console.log(error)
    ElMessage.error(error)
    return Promise.reject()
  }
)

service.interceptors.response.use(
  (response) => {
    const status = Number(response.data.code)
    const message = response.data.msg || '未知错误'
    // 登录过期
    if (status === -14) {
      if (isMagg) {
        ElMessage({
          message: message,
          type: 'error',
        })
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      setTimeout(() => {
        loginout()
      }, 1000)
      Promise.reject(new Error(message))
    }
    // -66 弹框信息
    if (status !== 0 && status !== -66) {
      if (isMagg) {
        ElMessage({
          message: message,
          type: 'error',
        })
        isMagg = false
        setTimeout(() => {
          isMagg = true
        }, 1000)
      }
      Promise.reject(new Error(message))
      return response.data
    }
    return response.data
  },
  (error) => {
    console.log(error)
    if (isMagg) {
      ElMessage({
        message: error,
        type: 'error',
      })
      isMagg = false
      setTimeout(() => {
        isMagg = true
      }, 1000)
    }
    Promise.reject()
    return error
  }
)

export default service
