import router from './router'
// import store from './store'
// import { ElMessage } from 'element-plus'

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title}`

  const hasToken = localStorage.getItem('ms_token')
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
      // const hasRoles =
      //   store.state.permission.routes &&
      //   store.state.permission.routes.length > 0
      // if (hasRoles) {
      //   if (to.meta.menuPermission) {
      //     store.commit('setRoles', to.meta.menuPermission)
      //   }
      //   next()
      // } else {
      //   try {
      //     await store.dispatch('permission/generateRoutes')
      //     next({ ...to, replace: true })
      //   } catch (error) {
      //     ElMessage.error(error || 'Has Error')
      //   }
      // }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login`)
    }
  }
})
