import { createStore } from 'vuex'
import tagsView from './modules/tagsView/index'
import permission from './modules/permission'
import getters from './getters'

export default createStore({
  state: {
    collapse: false,
    roles: [],
    HZhe:''
  },
  mutations: {
    // 侧边栏折叠
    handleCollapse(state, data) {
      state.collapse = data
    },
    setRoles(state, data) {
      state.roles = data
    },
    HzheFunction:(state,pathName) => {
      console.log(state)
      state.HZhe = pathName
    }
  },
  actions: {},
  modules: {
    tagsView,
    permission,
  },
  getters,
})
