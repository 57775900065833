<template>
  <router-view />
  <!-- <router-view v-if="isRouterActive" ></router-view> -->
</template>

<script>
  // import { ref, provide, nextTick } from 'vue'
  // export default {
  //   setup() {
  //     const isRouterActive = ref(true)
  //     const reload = () => {
  //       isRouterActive.value = false
  //       nextTick(() => {
  //         isRouterActive.value = true
  //       })
  //     }
  //     provide('reload', reload)
  //     return {
  //       isRouterActive,
  //       reload
  //     }
  //   }
  // }

</script>

<style lang="scss">
  // @import '~@/assets/css/main.scss';
</style>