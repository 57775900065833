import service from '@/utils/request'
import router from '@/router'
import dayjs from 'dayjs'
import axios from 'axios'
import comm from '@/utils/comm'
import { ElMessage } from 'element-plus'

// 获取区域滚动条高度
export const getConstHei = () => {
  return document.body.offsetHeight - 220 + 'px'
}

export const getnoTopConstHei = () => {
  return document.body.offsetHeight - 176 + 'px'
}
export const TimestampToTime = () => {
  let date = new Date()
  let year = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return year + M + D;
}
export const TimestampToTime1 = () => {
  let date = new Date()
  let year = date.getFullYear() + '-';
  var M = date.getMonth() + 1 + '-';
  var D = date.getDate()
  return year + M + D;
}
// 获取字典数据
export const downLoadXlsx = async (url, data, name) => {
  const role = localStorage.getItem('ms_token')
  let port = location.protocol
  let ip = comm.cesuUrl
  let requestUrl = `${port}//${ip}${url}`
  axios({
    headers: {
      token: role,
    },
    method: 'post',
    // responseType: 'blob',
    url: requestUrl,
    data: data,
  })
    .then((res) => {
      if (!res.data.code) {
        axios({
          headers: {
            token: role,
          },
          method: 'post',
          responseType: 'blob',
          url: requestUrl,
          data: data,
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel',
            })
            link.style.display = 'none' //去除a标签的样式
            // 设置连接
            link.href = URL.createObjectURL(blob)
            link.download = name
            document.body.appendChild(link)
            // 模拟点击事件
            link.click()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error',
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
//导出药品
export const downLoadDocx = async (url, data, name) => {
  const role = localStorage.getItem('ms_token')
  let port = location.protocol
  let ip = comm.cesuUrl
  let requestUrl = `${port}//${ip}${url}`
  axios({
    headers: {
      token: role,
    },
    method: 'post',
    // responseType: 'blob',
    url: requestUrl,
    data: data,
  })
    .then((res) => {
      if (!res.data.code) {
        axios({
          headers: {
            token: role,
          },
          method: 'post',
          responseType: 'blob',
          url: requestUrl,
          data: data,
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            link.style.display = 'none' //去除a标签的样式
            // 设置连接
            link.href = URL.createObjectURL(blob)
            link.download = name
            document.body.appendChild(link)
            // 模拟点击事件
            link.click()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error',
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
// 导出
export const gitDictList = async (item) => {
  let parsent = {
    parent_code_list: item,
  }
  let res = await service.post('/api/config/get_sub_config_multiple', parsent)
  if (res.code === 0) {
    return res.data.sub_config
  }
}

/**
 * 深拷贝
 * @param {*} obj
 */
export const clone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = clone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

// code获取对应字典数据
export const gitDictVal = (item, val) => {
  if (!item) return ''
  let name = ''
  item.map((obj) => {
    if (obj.code === val) {
      name = obj.name
    }
  })
  return name
}

/**
 * @param {str} str
 * @Desc 返回年月日
 */
export let ruleTimeYear = (str) => {
  if (str) {
    return dayjs(str).format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
}

/**
 * @param {str} str
 * @Desc 返回年月日
 */
export let TimeYear = (str) => {
  if (str) {
    return dayjs(str).format('YYYY-MM-DD')
  } else {
    return ''
  }
}

// 根据身份证获取出生日期
export let getBirthdayFromIdCard = (idCard) => {
  var birthday = ''
  if (idCard != null && idCard != '') {
    if (idCard.length == 15) {
      birthday = '19' + idCard.substr(6, 6)
    } else if (idCard.length == 18) {
      birthday = idCard.substr(6, 8)
    }

    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
  }

  return birthday
}

// 根据身份证获取出生日期,性别,年龄
export let IdCard = (idCard, type) => {
  if (type === 1) {
    let birthday = ''
    if (idCard != null && idCard != '') {
      if (idCard.length == 15) {
        birthday = '19' + idCard.substr(6, 6)
      } else if (idCard.length == 18) {
        birthday = idCard.substr(6, 8)
      }

      birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
    }
    return birthday
  }
  if (type === 2) {
    //获取性别
    if (parseInt(idCard.substr(16, 1)) % 2 == 1) {
      //男
      return '101110000'
    } else {
      //女
      return '101120000'
    }
  }
  if (type === 3) {
    //获取年龄
    let myDate = new Date()
    let month = myDate.getMonth() + 1
    let day = myDate.getDate()
    let age = myDate.getFullYear() - idCard.substring(6, 10) - 1
    if (
      idCard.substring(10, 12) < month ||
      (idCard.substring(10, 12) == month && idCard.substring(12, 14) <= day)
    ) {
      age++
    }
    return age
  }
}

// 退出登录
export const loginout = () => {
  localStorage.removeItem('ms_username')
  localStorage.removeItem('ms_token')
  localStorage.removeItem('ms_userData')
  router.push('/login')
}

export let getDiffDay = (date_1, date_2)=>{
  let totalDays, diffDate
  let myDate_1 = Date.parse(date_1)
  let myDate_2 = Date.parse(date_2)
  diffDate = Math.abs(myDate_1 - myDate_2) 
  totalDays = Math.floor(diffDate / (1000 * 3600 * 24))
  return totalDays 
}
export const getDay = (day)=>{
  var today = new Date()
  // 获取时间戳(毫秒级)
  /*
    day为1，则是，明天的时间戳
    day为-1，则是，昨天的时间戳
    day为-2，则是，前天的时间戳
  */
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  // Date.setTime(时间戳)：设置当前日期的时间
  today.setTime(targetday_milliseconds)
  console.log('today=', today) // today= Sun Mar 05 2023 16:14:56 GMT+0800 (中国标准时间)
  var tYear = today.getFullYear() // 年
  var tMonth = today.getMonth() // 月
  var tDate = today.getDate() // 日
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  console.log('返回年月日=', tYear + '-' + tMonth + '-' + tDate)
  return tYear + '-' + tMonth + '-' + tDate
}
export const doHandleMonth = (month)=> {
  var m = month
  if (month.toString().length == 1) {
    m = '0' + month
  }
  return m
}