<template>
  <div class="about">
    <v-header />
    <div class="container-menu">
      <v-sidebar />
    </div>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <!--      <div class="tagsView">-->
      <!--        <v-tags></v-tags>-->
      <!--      </div>-->
      <div class="content">
        <router-view v-slot="{ Component }" :key="$route.fullPath">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component" />
            </keep-alive>
          </transition>
        </router-view>
        <!-- <el-backtop target=".content"></el-backtop> -->
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import vHeader from '../components/Header.vue'
import vSidebar from '../components/SideBar/index.vue'
import vTags from '../components/TagsView/index.vue'
export default {
  components: {
    vHeader,
    vSidebar,
    vTags,
  },
  setup() {
    const store = useStore()
    const tagsList = []
    const collapse = computed(() => store.state.collapse)
    return {
      tagsList,
      collapse,
    }
  },
}
</script>

<style scoped lang="scss">
.container-menu {
  width: 200px;
  position: absolute;
  left: 0;
  top: 56px;
  height: calc(100% - 64px);
  overflow: hidden;
  /* overflow-y: scroll; */
}
</style>
