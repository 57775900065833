<template>
  <div ref="tagsView" class="tags-view-container" id="tags-view-container">
    <scroll-pane ref="scrollPaneRef" class="tags-view-wrapper">
      <!-- <router-link
        v-for="tag in visitedViews"
        ref="tag"
        :key="tag.path"
        :class="{ active: isActive(tag), affix: isAffix(tag) }"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
      >
        {{ tag.title }}
        <i class="clone" @click.prevent.stop="closeSelectedTag(tag)"></i>
      </router-link> -->
      <div v-for="tag in visitedViews" ref="tag" :key="tag.path"  @click="menuClick(tag)"
        :class="{ active: isActive(tag), affix: isAffix(tag) }" class="tags-view-item">
        {{ tag.title }}
        <i class="clone" @click.prevent.stop="closeSelectedTag(tag)"></i>
      </div>
    </scroll-pane>
  </div>
</template>

<script>
  import {
    computed,
    onMounted,
    ref,
    watch,
    reactive,
    toRefs,
    nextTick,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import path from 'path'
  import ScrollPane from './ScrollPane.vue'

  export default {
    components: {
      ScrollPane,
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const tagsView = ref(null)
      const tag = ref(null)
      const state = reactive({
        tagsView,
        tag,
        scrollPaneRef: null,
        affixTags: [],
        left: 0,
        top: 0,
        visible: false,
        selectedTag: {},
      })
      watch(route, () => {
        addTags()
      })
      watch(
        () => state.visible,
        (value) => {
          if (value) {
            document.body.addEventListener('click', closeMenu)
          } else {
            document.body.removeEventListener('click', closeMenu)
          }
        }
      )
      onMounted(() => {
        initTags()
        addTags()
      })
      const filterAffixTags = (router, basePath = '/') => {
        let tags = []
        router.forEach((item) => {
          if (item.meta && item.meta.affix) {
            let tagPath = path.resolve(basePath, item.path)
            tags.push({
              fullPath: tagPath,
              path: tagPath,
              name: item.name,
              meta: { ...item.meta },
            })
          }
          if (item.children) {
            const tempTags = filterAffixTags(item.children, item.path)
            if (tempTags.length >= 1) {
              tags = [...tags, ...tempTags]
            }
          }
        })
        return tags
      }
      const initTags = () => {
        const routes = store.state.permission.routes
        const affixTagList = filterAffixTags(routes)
        state.affixTags = affixTagList
        for (const tag of affixTagList) {
          store.dispatch('tagsView/addVisitedView', tag)
        }
      }

      const addTags = () => {
        const { name } = route
        if (name) {
          store.dispatch('tagsView/addView', route)
        }
        nextTick(() => {
          if (state.scrollPaneRef) {
            state.scrollPaneRef.scrollContainer.update()
          }
        })
      }

      const isActive = (row) => {
        return row.path === route.path
      }

      const isAffix = (tag) => {
        return tag.meta && tag.meta.affix
      }

      const closeSelectedTag = (view) => {
        // console.log(view)
        store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
          if (isActive(view)) {
            toLastView(visitedViews, view)
          }
        })
        nextTick(() => {
          state.scrollPaneRef.scrollContainer.update()
        })
      }

      const toLastView = (visitedViews, view) => {
        const latestView = visitedViews.slice(-1)[0]
        if (latestView) {
          router.push(latestView.fullPath)
        } else {
          if (view.name === 'dashboard') {
            router.replace({ path: '/redirect' + view.fullPath })
          } else {
            router.push('/')
          }
          // if (view.name === 'dashboard') {
          //   router.replace({ path: '/redirect' + view.fullPath })
          // }  else if (view.name === 'dashboard') {
          //   router.replace({ path: view.fullPath })
          // }else {
          //   router.push('/')
          // }
        }
      }
      const menuClick = (val) => {

        console.log(val)
        if (val.name == "patientDetails") {
          nextTick(() => {
            let time = new Date().getTime()
            console.log(time, val.fullPath)
            // router.replace({ path:  val.fullPath })
            router.replace({
              path: val.fullPath + '&time=' + time,
              query: val.query,
            })
          })

        } else {
          router.push({
            path: val.path,
            query: val.query,
            fullPath: val.fullPath
          })
        }

        // alert(1)
      }
      const openMenu = (tag, e) => {
        const menuMinWidth = 105
        const offsetLeft = tagsView.value.getBoundingClientRect().left
        const offsetWidth = tagsView.value.offsetWidth
        const maxLeft = offsetWidth + menuMinWidth
        const left = e.clientX - offsetLeft + 15

        if (left > maxLeft) {
          state.left = maxLeft
        } else {
          state.left = left
        }

        state.top = e.clientY - 60
        state.visible = true
        state.selectedTag = tag
      }
      const closeMenu = () => {
        state.visible = false
      }

      return {
        ...toRefs(state),
        visitedViews: computed(() => store.state.tagsView.visitedViews),
        isActive,
        isAffix,
        openMenu,
        closeSelectedTag,
        menuClick
      }
    },
  }
</script>

<style lang="scss" scoped>
  .clone {
    width: 24px;
    height: 24px;
    background: url(../../assets/img/ions/clone.png);
    margin-left: 8px;
  }

  .tags-view-container {
    height: 40px;
    margin-top: 4px;
    padding-right: 8px;

    &:deep(.el-scrollbar__view) {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .tags-view-wrapper {
      .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        border: 0;
        color: #3166ae;
        background: #fff;
        padding: 0 16px;
        font-size: 14px;
        margin-left: 8px;
        // margin-top: 12px;
        border-radius: 4px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        border: 1px solid #eaeff7;
        border-radius: 4px 4px 0px 0px;

        .clone {
          display: none;
        }

        &.active,
        &:hover {
          background-color: rgba(49, 102, 174, 0.1);
          color: #3166ae;
          border: 1px solid rgba(49, 102, 174, 0.1);

          // border-color: rgba(49, 102, 174, 0.1);
          .clone {
            display: inline-block;
          }
        }

        &.affix {

          &.active,
          &:hover {
            .clone {
              display: none;
            }
          }
        }
      }
    }
  }
</style>