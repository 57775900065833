<template>
  <div v-if="!item.meta || (item.meta && !item.meta.hidden)">
    <template v-if="isShowLevelMenu(item.children, item)">
      <router-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path)"
      >
        <el-menu-item :index="resolvePath(onlyOneChild.path)">
          <i
            v-if="onlyOneChild.meta.icon!=''"
            :class="['barIons', onlyOneChild.meta.icon]"
          ></i>
          <b v-else style="margin-right: 20px"></b>
          <template #title>
            <span>{{ onlyOneChild.meta.title }}</span>
          </template>
        </el-menu-item>
      </router-link>
    </template>
    <template v-else>
      <template v-if="!item.meta">
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
          :base-path="resolvePath(child.path)"
          :changeSkin="changeSkin"
          class="nest-menu"
        />
      </template>
      <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)">
        <template #title>
          <i v-if="item.meta.icon!=''" :class="['barIons', item.meta.icon]"></i>
          <b v-else style="margin-right: 20px"></b>
          <span>{{ item.meta.title }}</span>
        </template>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
          :base-path="resolvePath(child.path)"
          :changeSkin="changeSkin"
          class="nest-menu"
        />
      </el-sub-menu>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import path from 'path'
export default defineComponent({
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
      default: '',
    },
    changeSkin: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let onlyOneChild = ref(null)
    const isShowLevelMenu = (children = [], parent) => {
      const showingChildren = children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false
        } else {
          onlyOneChild.value = item
          return true
        }
      })

      // if (showingChildren.length === 1) {
      //   return true
      // }

      if (showingChildren.length === 0) {
        onlyOneChild.value = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    }

    const resolvePath = (routePath) => {
      return path.resolve(props.basePath, routePath)
    }

    return {
      isShowLevelMenu,
      resolvePath,
      onlyOneChild,
    }
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.white-bg {
  color: #909399 !important;
}
.red-bg {
  color: #fff !important;
}
</style>
